import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import AppAlert from '@m/components/AppAlert';
import { useUnit } from 'effector-react';

import AppFooter from 'apps/request/components/AppFooter';
import AppHeader from 'apps/request/components/AppHeader';
import VisitCard from 'apps/request/components/VisitCard';
import VisitColumn from 'apps/request/components/VisitColumn';
import VisitGroup from 'apps/request/components/VisitGroup';
import { useHospitalityPolling } from 'apps/request/hooks';
import { getIntakePartnerDetailsByUserId, intakeStore } from 'apps/request/state/intake';
import { staysStore } from 'apps/request/state/stays';
import {
  disableMobileViewSelector,
  enableMobileViewSelector,
  hideAddressHeader,
  setDashboardView,
  showAddressHeader,
  uiStore,
} from 'apps/request/state/ui';
import {
  ColumnType,
  DashboardView,
  HospitalityStay,
  HospitalityStayStatus,
  RequestStatus,
} from 'apps/request/types';
import { breakpoints } from 'apps/request/utils/breakpoints';
import { useIsAuthed } from 'apps/specialist/hooks/useIsAuthed';
import { logout, showLoginPrompt, userStore } from 'apps/specialist/state/user';

import {
  ColumnsContainer,
  IntakePartnerRequestErrorLogoutButton,
  IntakePartnerRequestErrorSection,
  main_sec_styles,
} from './RequestContainer.styled';

const AppModal = lazy(() => import('apps/request/components/AppModal'));
const AppMenu = lazy(() => import('apps/request/components/AppMenu'));

// SEE: Header.styled.ts & Footer.styled.ts
const SCREEN_MINUS = {
  extraLarge: 246, // header: 50px, view selector: 64px, main section top and bottom padding: 32px, footer: 100px
  large: 216, // header: 50px, view selector: 64px, main section top and bottom padding: 32px, footer: 70px
  medium: 211, // header: 50px, view selector: 64px, main section top and bottom padding: 32px, footer: 65px
  small: 187, // header: 50px, view selector: 50px, main section top and bottom padding: 32px, footer: 55px
};

function RequestContainer() {
  const { isAppModalVisible, dashboardView } = useUnit(uiStore);

  const { user } = useUnit(userStore);

  const isAuthed = useIsAuthed();

  const { hospitalityStays } = useUnit(staysStore);

  const { intakePartnerDetails } = useUnit(intakeStore);

  const { displayAddressInHeader, showMobileViewSelector } = useUnit(uiStore);

  const [columnMaxHeight, setColumnMaxHeight] = useState(() => {
    if (window.innerWidth > breakpoints.large) {
      return window.innerHeight - SCREEN_MINUS.extraLarge;
    }
    if (window.innerWidth <= breakpoints.large && window.innerWidth > breakpoints.medium) {
      return window.innerHeight - SCREEN_MINUS.large;
    }
    if (window.innerWidth <= breakpoints.medium && window.innerWidth > breakpoints.small) {
      return window.innerHeight - SCREEN_MINUS.medium;
    }
    return window.innerHeight - SCREEN_MINUS.small;
  });

  const [intakeDetailsFetchFailed, setIntakeDetailsFetchFailed] = useState(false);

  const handleWindowResize = useCallback(() => {
    if (window.innerWidth <= breakpoints.large) {
      if (!showMobileViewSelector) {
        enableMobileViewSelector();
      }

      if (displayAddressInHeader) {
        hideAddressHeader();
      }

      if (dashboardView === DashboardView.TODO) {
        setDashboardView({ view: DashboardView.TOPARK });
      }
    } else {
      if (showMobileViewSelector) {
        disableMobileViewSelector();
      }

      if (!displayAddressInHeader) {
        showAddressHeader();
      }

      if (dashboardView === DashboardView.TOPARK || dashboardView === DashboardView.TOPROP) {
        setDashboardView({ view: DashboardView.TODO });
      }
    }

    if (window.innerWidth > breakpoints.large) {
      setColumnMaxHeight(window.innerHeight - SCREEN_MINUS.extraLarge);
    } else if (window.innerWidth <= breakpoints.large && window.innerWidth > breakpoints.medium) {
      setColumnMaxHeight(window.innerHeight - SCREEN_MINUS.large);
    } else if (window.innerWidth <= breakpoints.medium && window.innerWidth > breakpoints.small) {
      setColumnMaxHeight(window.innerHeight - SCREEN_MINUS.medium);
    } else {
      setColumnMaxHeight(window.innerHeight - SCREEN_MINUS.small);
    }
  }, [dashboardView, displayAddressInHeader, showMobileViewSelector]);

  const handleLogout = async () => {
    const { success } = await logout();
    if (success) {
      showLoginPrompt();
    }
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  useEffect(() => {
    if (user?.id) {
      getIntakePartnerDetailsByUserId(user.id)
        .then((res) => {
          if (!res.success) {
            setIntakeDetailsFetchFailed(true);
          }
        })
        .catch(() => {
          setIntakeDetailsFetchFailed(true);
        });
    }
  }, [isAuthed, user]);

  useHospitalityPolling({ interval: 4000, enabled: true });

  const {
    toGarageRequested,
    toGarageInProgress,
    parkedData,
    toHotelRequested,
    toHotelInProgress,
    toHotelComplete,
  } = useMemo(() => {
    const categorizedStays: { [key: string]: HospitalityStay[] } = {
      toGarageRequested: [],
      toGarageInProgress: [],
      parkedData: [],
      toHotelRequested: [],
      toHotelInProgress: [],
      toHotelComplete: [],
    };

    if (Array.isArray(hospitalityStays) && hospitalityStays.length > 0) {
      hospitalityStays.forEach((details) => {
        switch (details.hospitalityStay.status.name) {
          case HospitalityStayStatus.PickUpRequested:
            categorizedStays.toGarageRequested.push(details);
            break;
          case HospitalityStayStatus.PickUpInProgress:
            categorizedStays.toGarageInProgress.push(details);
            break;
          case HospitalityStayStatus.Parked:
            categorizedStays.parkedData.push(details);
            break;
          case HospitalityStayStatus.DropOffRequested:
            categorizedStays.toHotelRequested.push(details);
            break;
          case HospitalityStayStatus.DropOffInProgress:
            categorizedStays.toHotelInProgress.push(details);
            break;
          case HospitalityStayStatus.DroppedOff:
            categorizedStays.toHotelComplete.push(details);
            break;
          default:
            break;
        }
      });
    }

    return categorizedStays;
  }, [hospitalityStays]);

  if (!intakePartnerDetails && intakeDetailsFetchFailed) {
    return (
      <IntakePartnerRequestErrorSection>
        <div>This User does not have access to the Request App.</div>
        <div>Log Out and Sign In with a different User.</div>
        <IntakePartnerRequestErrorLogoutButton type="button" onClick={handleLogout}>
          Logout
        </IntakePartnerRequestErrorLogoutButton>
      </IntakePartnerRequestErrorSection>
    );
  }

  if (!intakePartnerDetails) return null;

  return (
    <>
      <AppAlert />

      {isAppModalVisible && (
        <Suspense fallback={null}>
          <AppModal />
        </Suspense>
      )}

      <Suspense fallback={null}>
        <AppMenu />
      </Suspense>

      <AppHeader
        name={intakePartnerDetails.name}
        address={intakePartnerDetails.address}
        city={intakePartnerDetails.city}
        state={intakePartnerDetails.state}
        zipcode={intakePartnerDetails.zipcode}
      />

      <main css={main_sec_styles}>
        <ColumnsContainer colMaxHeight={columnMaxHeight}>
          {dashboardView === DashboardView.PARKED && (
            <VisitColumn type={ColumnType.CarsParked} count={parkedData.length}>
              <VisitGroup
                count={parkedData.length}
                column={ColumnType.CarsParked}
                status={RequestStatus.Parked}
              >
                {parkedData.map((parkedItem, i) => (
                  <VisitCard key={parkedItem.hospitalityStay.id} details={parkedItem} />
                ))}
              </VisitGroup>
            </VisitColumn>
          )}

          {dashboardView === DashboardView.TODO && (
            <>
              <VisitColumn type={ColumnType.CarsToGarage}>
                <VisitGroup
                  count={toGarageRequested.length}
                  column={ColumnType.CarsToGarage}
                  status={RequestStatus.Requested}
                >
                  {toGarageRequested.length > 0
                    ? toGarageRequested.map((item, i) => (
                        <VisitCard key={item.hospitalityStay.id} details={item} />
                      ))
                    : null}
                </VisitGroup>

                <VisitGroup
                  count={toGarageInProgress.length}
                  column={ColumnType.CarsToGarage}
                  status={RequestStatus.InProgress}
                >
                  {toGarageInProgress.length > 0
                    ? toGarageInProgress.map((item, i) => (
                        <VisitCard key={item.hospitalityStay.id} details={item} />
                      ))
                    : null}
                </VisitGroup>
              </VisitColumn>

              <VisitColumn type={ColumnType.CarsToHotel}>
                <VisitGroup
                  count={toHotelRequested.length}
                  column={ColumnType.CarsToHotel}
                  status={RequestStatus.Requested}
                >
                  {toHotelRequested.length > 0
                    ? toHotelRequested.map((item, i) => (
                        <VisitCard key={item.hospitalityStay.id} details={item} />
                      ))
                    : null}
                </VisitGroup>

                <VisitGroup
                  count={toHotelInProgress.length}
                  column={ColumnType.CarsToHotel}
                  status={RequestStatus.InProgress}
                >
                  {toHotelInProgress.length > 0
                    ? toHotelInProgress.map((item, i) => (
                        <VisitCard key={item.hospitalityStay.id} details={item} />
                      ))
                    : null}
                </VisitGroup>

                <VisitGroup
                  count={toHotelComplete.length}
                  column={ColumnType.CarsToHotel}
                  status={RequestStatus.Complete}
                >
                  {toHotelComplete.length > 0
                    ? toHotelComplete.map((item, i) => (
                        <VisitCard key={item.hospitalityStay.id} details={item} />
                      ))
                    : null}
                </VisitGroup>
              </VisitColumn>
            </>
          )}

          {/* MOBILE VIEWS */}

          {dashboardView === DashboardView.TOPARK && (
            <VisitColumn type={ColumnType.CarsToGarage}>
              <VisitGroup
                count={toGarageRequested.length}
                column={ColumnType.CarsToGarage}
                status={RequestStatus.Requested}
              >
                {toGarageRequested.length > 0
                  ? toGarageRequested.map((item, i) => (
                      <VisitCard key={item.hospitalityStay.id} details={item} />
                    ))
                  : null}
              </VisitGroup>

              <VisitGroup
                count={toGarageInProgress.length}
                column={ColumnType.CarsToGarage}
                status={RequestStatus.InProgress}
              >
                {toGarageInProgress.length > 0
                  ? toGarageInProgress.map((item, i) => (
                      <VisitCard key={item.hospitalityStay.id} details={item} />
                    ))
                  : null}
              </VisitGroup>
            </VisitColumn>
          )}

          {dashboardView === DashboardView.TOPROP && (
            <VisitColumn type={ColumnType.CarsToHotel}>
              <VisitGroup
                count={toHotelRequested.length}
                column={ColumnType.CarsToHotel}
                status={RequestStatus.Requested}
              >
                {toHotelRequested.length > 0
                  ? toHotelRequested.map((item, i) => (
                      <VisitCard key={item.hospitalityStay.id} details={item} />
                    ))
                  : null}
              </VisitGroup>

              <VisitGroup
                count={toHotelInProgress.length}
                column={ColumnType.CarsToHotel}
                status={RequestStatus.InProgress}
              >
                {toHotelInProgress.length > 0
                  ? toHotelInProgress.map((item, i) => (
                      <VisitCard key={item.hospitalityStay.id} details={item} />
                    ))
                  : null}
              </VisitGroup>

              <VisitGroup
                count={toHotelComplete.length}
                column={ColumnType.CarsToHotel}
                status={RequestStatus.Complete}
              >
                {toHotelComplete.length > 0
                  ? toHotelComplete.map((item, i) => (
                      <VisitCard key={item.hospitalityStay.id} details={item} />
                    ))
                  : null}
              </VisitGroup>
            </VisitColumn>
          )}
        </ColumnsContainer>
      </main>

      <AppFooter />
    </>
  );
}

export default RequestContainer;
