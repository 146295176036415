import styled from 'styled-components';

import { VisitCardActionButtonType, VisitCardVariant } from 'apps/request/types';

export const CardContainer = styled.div<{ variant?: VisitCardVariant }>`
  display: block;
  text-align: left;
  cursor: pointer;
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid
    ${({ variant }) => (variant === VisitCardVariant.SearchResult ? '#000000' : '#CDCACA')};
  margin-bottom: 8px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const DetailsSection = styled.div`
  display: flex;
  width: 100%;
  gap: 2px;
`;

export const NameAndDetails = styled.div`
  flex: 1;
  overflow: hidden;
`;

export const VisitTypeAndMoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const CardHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const Name = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
`;

export const TimeParked = styled.section`
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 4px;
`;

export const Details = styled.section`
  display: flex;
  gap: 12px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  background: #f5f5f5;
  border-radius: 14px;
  padding: 2px 8px;

  & > div {
    display: flex;
    align-items: center;
    gap: 4px;
    text-wrap: nowrap;
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const RequestPill = styled.div<{ isUrgent: boolean }>`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 4px;
  padding: 4px 8px;
  border-radius: 30px;
  background: #f5f5f5;

  ${({ isUrgent }) =>
    isUrgent &&
    `
    background: #DF795F;
    color: #FFFFFF;
  `}
`;

export const ActionButtonSection = styled.div`
  margin-top: 16px;
`;

export const CardActionButton = styled.button<{ variant: VisitCardActionButtonType }>`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  background: #5f59ff;
  border: none;
  width: 100%;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }

  ${({ variant }) =>
    variant === VisitCardActionButtonType.GuestRecievedVehicle &&
    `
    background: #FFFFFF;
    border: 1px solid #538A58;
    color: #538A58;
    & > span {
      flex-grow: 1;
      text-align: center;
    }

    & > svg {
      margin-left: auto;
    }
  `}
`;
