import { useEffect } from 'react';

import { useHeapIdentifyUser } from '@m/hooks/useHeapIdentifyUser';
import { hasIntakePermissions } from '@m/utils/intake';
import { useUnit } from 'effector-react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import LoginPromptContainer from 'apps/specialist/containers/LoginPromptContainer';
import { useIsAuthed } from 'apps/specialist/hooks/useIsAuthed';
import { getUser, logout, userStore } from 'apps/specialist/state/user';

import RequestContainer from './containers/RequestContainer/RequestContainer';

function Request() {
  const history = useHistory();
  const { pathname } = useLocation();
  const { user } = useUnit(userStore);

  const isAuthed = useIsAuthed();

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    async function handleInvalidPermissions() {
      await logout();
      history.replace('/login');
    }
    if (user && !hasIntakePermissions(user.effectivePermissions)) {
      handleInvalidPermissions();
    } else if (isAuthed === false && pathname !== '/login') {
      history.replace('/login');
    }
  }, [isAuthed, pathname, history, user]);

  useHeapIdentifyUser({
    authenticated: isAuthed,
    user,
  });

  return (
    <Switch>
      <Route exact path="/">
        {isAuthed && <RequestContainer />}
      </Route>
      <Route exact path="/login">
        <LoginPromptContainer disableSSOAuth />
      </Route>
    </Switch>
  );
}

export default Request;
