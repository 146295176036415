import { applyReducers, DoneHandler } from '@m/effector-utils';
import { APIResponse } from '@m/utils/http';
import { createStore, Effect } from 'effector';

import { HospitalityStay } from 'apps/request/types';

import { fetchOpenHospitalityStays } from './actions';

type State = {
  hospitalityStays: HospitalityStay[];
};

const initialState: State = {
  hospitalityStays: [],
};

const store = createStore(initialState);

type Reducers = {
  getIntakePartnerDetailsByUserId: {
    action: Effect<Parameters<typeof fetchOpenHospitalityStays>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchOpenHospitalityStays>[0], State>;
  };
};

export const reducers: Reducers = {
  getIntakePartnerDetailsByUserId: {
    action: fetchOpenHospitalityStays,
    done: (state, { result: { success, data } }) => ({
      ...state,
      hospitalityStays: data.hospitalityStays,
    }),
  },
};

export default applyReducers({ store, reducers });
