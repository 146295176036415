import styled from 'styled-components';

export const ColumnContainer = styled.section`
  flex: 1;
  border-radius: 16px;
  background: #ffffff;
  height: 100%;
  padding: 16px;
  min-width: 325px;
  max-width: 884px;
  margin: auto;
`;

export const ColHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  & > svg:hover {
    cursor: pointer;
  }
`;

export const ColType = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const ColTypeText = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-rendering: geometricPrecision;
`;

export const ColContent = styled.div`
  flex: 1;
  overflow: scroll;
  height: 100%;
  scrollbar-width: none;
  padding-bottom: 24px;
  user-select: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
