import { ReactNode } from 'react';

import { ReactComponent as AddIcon } from '@m/assets/svg/icon-plus-purple.svg';
import { ReactComponent as SearchIcon } from '@m/assets/svg/icon-search-black.svg';
import { useUnit } from 'effector-react';

import { showAppModal, uiStore } from 'apps/request/state/ui';
import { ColumnType, DashboardView } from 'apps/request/types';

import SearchForm from '../Forms/SearchForm';
import SendToGarageForm from '../Forms/SendToGarageForm';

import { ColContent, ColHeader, ColType, ColTypeText, ColumnContainer } from './VisitColumn.styled';

type Props = {
  type: ColumnType;
  children: ReactNode;
  count?: number;
};

const displayHeaderText = (type: ColumnType, count?: number) => {
  if (type === ColumnType.CarsParked) {
    if (count) {
      return <>Parked &bull; {count}</>;
    }
    return 'Parked';
  }

  if (type === ColumnType.CarsToGarage) {
    return 'Send to Park';
  }

  if (type === ColumnType.CarsToHotel) {
    return 'Send to Property';
  }

  return null;
};

const displayAddButton = ({
  type,
  onAddButtonClick,
}: {
  type: ColumnType;
  onAddButtonClick: () => void;
}): ReactNode => {
  if (type === ColumnType.CarsToGarage || type === ColumnType.CarsToHotel) {
    return <AddIcon css="cursor: pointer;" width={24} onClick={onAddButtonClick} />;
  }
  return <SearchIcon width={24} onClick={() => showAppModal({ content: <SearchForm /> })} />;
};

function VisitColumn({ type, children, count }: Props) {
  const { dashboardView, showMobileViewSelector } = useUnit(uiStore);

  const onAddButtonClick = () => {
    if (type === ColumnType.CarsToGarage) {
      showAppModal({ content: <SendToGarageForm /> });
    }

    if (type === ColumnType.CarsToHotel) {
      showAppModal({ content: <SearchForm /> });
    }
  };
  return (
    <ColumnContainer>
      {showMobileViewSelector && dashboardView === DashboardView.PARKED ? null : (
        <ColHeader>
          <ColType>
            <ColTypeText>{displayHeaderText(type, count)}</ColTypeText>
          </ColType>
          {displayAddButton({ type, onAddButtonClick })}
        </ColHeader>
      )}
      <ColContent>{children}</ColContent>
    </ColumnContainer>
  );
}

export default VisitColumn;
