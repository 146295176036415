import { createEvent } from 'effector';

import { ColumnType, DashboardView } from 'apps/request/types';

export const showAppModal = createEvent<{
  content: JSX.Element;
  timeout?: number;
  styleOverrides?: string;
}>();

export const hideAppModal = createEvent<void>();

export const showAppMenu = createEvent<void>();

export const hideAppMenu = createEvent<void>();

export const setDashboardView = createEvent<{ view: DashboardView }>();

export const setUrgentRequest = createEvent<{ whichColumn: ColumnType }>();

export const removeUrgentRequest = createEvent<{ whichColumn: ColumnType }>();

export const enableMobileViewSelector = createEvent<void>();

export const disableMobileViewSelector = createEvent<void>();

export const showAddressHeader = createEvent<void>();

export const hideAddressHeader = createEvent<void>();
