import { Children, isValidElement, ReactElement, ReactNode, useState } from 'react';

import { ReactComponent as Caret } from '@m/assets/svg/icon-caret-black.svg';

import { ColumnType, RequestStatus, VisitCardProps } from 'apps/request/types';

import {
  EmptyGroup,
  FilterSortButton,
  FilterSortSection,
  GroupContainer,
  GroupContent,
  GroupHeader,
} from './VisitGroup.styled';

type Props = {
  status: RequestStatus;
  column: ColumnType;
  count: number;
  isUrgent?: boolean;
  children: ReactNode | null;
};

enum Filter {
  All = 'All',
  Overnight = 'Overnight',
  Transient = 'Transient',
}

const getColumnStatusLabel = ({
  column,
  status,
}: {
  column: ColumnType;
  status: RequestStatus;
}) => {
  let colLabel;
  let statusLabel;

  if (status === RequestStatus.Requested) {
    statusLabel = 'Requested';
  }

  if (status === RequestStatus.InProgress) {
    statusLabel = 'In Progress';
  }

  if (status === RequestStatus.Parked) {
    statusLabel = '';
  }

  if (status === RequestStatus.Complete) {
    statusLabel = 'Complete';
  }

  if (column === ColumnType.CarsToGarage) {
    colLabel = 'Send to Park';
  }

  if (column === ColumnType.CarsToHotel) {
    colLabel = 'Send to Property';
  }

  if (column === ColumnType.CarsParked) {
    colLabel = 'Parked';
  }

  return {
    column: {
      regular: colLabel,
      lowercase: colLabel?.toLowerCase(),
    },
    status: {
      regular: statusLabel,
      lowercase: statusLabel?.toLowerCase(),
    },
  };
};

const getFilterLabel = (filter: Filter) => {
  if (filter === Filter.All) return 'All Guests';
  if (filter === Filter.Overnight) return 'Overnight';
  if (filter === Filter.Transient) return 'Visitor';

  throw new Error(`Invalid filter passed: ${filter}`);
};

function VisitGroup({ status, column, count, isUrgent, children }: Props) {
  const labels = getColumnStatusLabel({ status, column });

  const [collapsed, setCollapsed] = useState(false);
  const [reverseParkedSort, setRevereParkSor] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<Filter>(Filter.All);

  const sortedChildren = Children.toArray(children)
    .filter((child): child is ReactElement<VisitCardProps> => isValidElement(child))
    .filter((child) => {
      if (currentFilter === Filter.All) return true;
      const stayType = child.props.details.hospitalityStay.stayType.name;
      return stayType === currentFilter;
    })
    .sort((a, b) => {
      const lastNameA = a.props.details.hospitalityStay.driverLastName.toLowerCase();
      const lastNameB = b.props.details.hospitalityStay.driverLastName.toLowerCase();

      if (lastNameA < lastNameB) return -1;
      if (lastNameA > lastNameB) return 1;
      return 0;
    });

  const getSortedChildren = () => {
    if (reverseParkedSort) {
      return sortedChildren.reverse();
    }

    return sortedChildren;
  };

  return (
    <>
      {!children && (
        <EmptyGroup>{`No ${labels.column.lowercase} ${labels.status.lowercase}`}</EmptyGroup>
      )}

      {children && (
        <GroupContainer
          status={status}
          isCollapsed={collapsed}
          isUrgent={isUrgent}
          parked={column === ColumnType.CarsParked}
        >
          <GroupHeader>
            <div>
              {column === ColumnType.CarsParked ? (
                <span>{getFilterLabel(currentFilter)}</span>
              ) : (
                <span>{`${labels.column.regular} ${labels.status.regular}`}</span>
              )}{' '}
              &bull; {column === ColumnType.CarsParked ? sortedChildren.length : count}
            </div>

            {column !== ColumnType.CarsParked && (
              <Caret onClick={() => setCollapsed((state) => !state)} />
            )}

            {column === ColumnType.CarsParked && (
              <FilterSortSection>
                <FilterSortButton
                  type="button"
                  reverse={false}
                  onClick={() => {
                    if (currentFilter === Filter.All) setCurrentFilter(Filter.Overnight);
                    if (currentFilter === Filter.Overnight) setCurrentFilter(Filter.Transient);
                    if (currentFilter === Filter.Transient) setCurrentFilter(Filter.All);
                  }}
                >
                  <Caret />
                  {getFilterLabel(currentFilter)}
                </FilterSortButton>
                <FilterSortButton
                  type="button"
                  reverse={reverseParkedSort}
                  onClick={() => setRevereParkSor((prev) => !prev)}
                >
                  <Caret />
                  {reverseParkedSort ? 'Z-A (Last)' : 'A-Z (Last)'}
                </FilterSortButton>
              </FilterSortSection>
            )}
          </GroupHeader>
          <GroupContent>
            {column === ColumnType.CarsParked ? getSortedChildren() : children}
          </GroupContent>
        </GroupContainer>
      )}
    </>
  );
}

export default VisitGroup;
