import styled from 'styled-components';

import { RequestStatus } from 'apps/request/types';

export const EmptyGroup = styled.div`
  padding: 16px;
  text-align: center;
  width: 100%;
  color: #bebebe;
  font-weight: 500;
  background: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const GroupContainer = styled.div<{
  status: RequestStatus;
  isCollapsed: boolean;
  isUrgent?: boolean;
  parked?: boolean;
}>`
  border-radius: 8px;
  margin-bottom: 16px;
  background: #f5f5f5;

  & > header {
    color: #000000;
    ${({ status }) => status === RequestStatus.Requested && 'border-bottom: 4px solid #DF795F;'}
    ${({ status }) => status === RequestStatus.InProgress && 'border-bottom: 4px solid #F0D470;'}
    ${({ status }) => status === RequestStatus.Complete && 'border-bottom: 4px solid #538A58;'}
    ${({ status }) => status === RequestStatus.Parked && 'border-bottom: 4px solid #CBCBCB;'}
  }

  ${({ isCollapsed, parked }) =>
    isCollapsed &&
    !parked &&
    `
     & > section {
      display: none;
     }

     & > header {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
     }

     & > header > svg {
      transform: rotate(180deg);
     }
  `}

  ${({ isUrgent, status }) =>
    status === RequestStatus.Requested &&
    isUrgent &&
    `
     & > header {
      color: #FFFFFF;
      background: #DF795F;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
     }
  `}
`;

export const GroupHeader = styled.header`
  padding: 10px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > svg {
    cursor: pointer;
  }
`;

export const GroupContent = styled.section`
  padding: 10px 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const FilterSortSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const FilterSortButton = styled.button<{ reverse: boolean }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  border: none;
  background: none;
  cursor: pointer;
  color: #000000;

  & > svg {
    transform: rotate(180deg);
  }

  ${({ reverse }) =>
    reverse &&
    `
    & > svg {
      transform: rotate(0deg);
    }
  `}
`;
