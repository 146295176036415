import { useState } from 'react';

import { ReactComponent as ArrowRightIcon } from '@m/assets/svg/arrow-right-white.svg';
import { ReactComponent as CarIcon } from '@m/assets/svg/car.svg';
import { ReactComponent as GreenCheckIcon } from '@m/assets/svg/icon-check-green.svg';
import { ReactComponent as DoorIcon } from '@m/assets/svg/icon-door-black.svg';
import { ReactComponent as InfoIcon } from '@m/assets/svg/icon-info-purple.svg';
import { ReactComponent as MoonIcon } from '@m/assets/svg/icon-moon-filled-black.svg';
import { ReactComponent as NoPaymentIcon } from '@m/assets/svg/icon-no-payment-red.svg';
import { ReactComponent as TicketIcon } from '@m/assets/svg/icon-ticket-black.svg';
import { ReactComponent as TransientIcon } from '@m/assets/svg/icon-transient-black.svg';
import { showAlert } from '@m/state/alert';
import { useUnit } from 'effector-react';

import { HospitalityService } from 'apps/request/services';
import { intakeStore } from 'apps/request/state/intake';
import { fetchOpenHospitalityStays } from 'apps/request/state/stays';
import { hideAppModal, showAppModal } from 'apps/request/state/ui';
import {
  HospitalityStay,
  HospitalityStayStatus,
  HospitalityStayType,
  VisitCardActionButtonType,
  VisitCardProps,
  VisitCardVariant,
} from 'apps/request/types';

import { showRequestSuccessAlert } from '../Alerts/RequestSuccess/RequestSuccess';
import GuestDetailsForm from '../Forms/GuestDetailsForm';
import Timestamp from '../Timestamp';

import {
  CardContainer,
  CardHeader,
  Name,
  Details,
  FlexDiv,
  TimeParked,
  CardActionButton,
  ActionButtonSection,
  DetailsSection,
  NameAndDetails,
  VisitTypeAndMoreInfo,
} from './VisitCard.styled';

const isParked = (details: HospitalityStay) =>
  details.hospitalityStay.status.name === HospitalityStayStatus.Parked;
const isSearchResult = (variant?: VisitCardVariant) => variant === VisitCardVariant.SearchResult;
const isParkedSearchResult = ({ details, variant }: VisitCardProps) =>
  isParked(details) && isSearchResult(variant);
const isReturnedToHotel = (details: HospitalityStay) =>
  details.hospitalityStay.status.name === HospitalityStayStatus.DroppedOff;

const getStatusLabel = (status: string) => {
  if (status === HospitalityStayStatus.Parked) return 'Parked';

  if (
    status === HospitalityStayStatus.DropOffRequested ||
    status === HospitalityStayStatus.PickUpRequested
  )
    return 'Requested';

  if (
    status === HospitalityStayStatus.DropOffInProgress ||
    status === HospitalityStayStatus.PickUpInProgress
  )
    return 'In Progress';

  if (status === HospitalityStayStatus.DroppedOff) return 'Dropped Off';

  return 'Completed';
};

const hasRoomNumber = ({ details }: { details: HospitalityStay }) =>
  !!details.hospitalityStay.roomNumber;

const shouldShowTimestamp = (details: HospitalityStay) => {
  switch (details.hospitalityStay.status.name) {
    case HospitalityStayStatus.DropOffRequested:
      return true;
    case HospitalityStayStatus.PickUpRequested:
      return true;
    case HospitalityStayStatus.PickUpInProgress:
      return true;
    case HospitalityStayStatus.DropOffInProgress:
      return true;
    default:
      return false;
  }
};

const getVehicleEventTimeBasedOnRequestStatus = (details: HospitalityStay) => {
  if (details.vehicleEvent) {
    /* FYI: We want time since inital request, not time since typeof request */

    if (details.hospitalityStay.status.name === HospitalityStayStatus.PickUpRequested) {
      return details.vehicleEvent.pickUpRequestedAt;
    }

    if (details.hospitalityStay.status.name === HospitalityStayStatus.PickUpInProgress) {
      return details.vehicleEvent.pickUpRequestedAt;
    }

    if (details.hospitalityStay.status.name === HospitalityStayStatus.DropOffRequested) {
      return details.vehicleEvent.dropOffRequestedAt;
    }

    if (details.hospitalityStay.status.name === HospitalityStayStatus.DropOffInProgress) {
      return details.vehicleEvent.dropOffRequestedAt;
    }

    if (details.hospitalityStay.status.name === HospitalityStayStatus.DroppedOff) {
      return details.vehicleEvent.droppedOffAt;
    }
  }

  return null;
};

function VisitCard({ details, variant }: VisitCardProps) {
  const {
    driverFirstName,
    driverLastName,
    guestFirstName,
    guestLastName,
    hotelTicketNumber,
    roomNumber,
    stayType,
  } = details.hospitalityStay;

  const { intakePartnerDetails } = useUnit(intakeStore);

  const [isRequesting, setIsRequesting] = useState(false);
  const [showActionButton, setShowActionButton] = useState(false);

  const handleSendToHotelRequest = async () => {
    setIsRequesting(true);

    const response = await HospitalityService.requestDropoff(details.hospitalityStay.id);

    if (response.success && intakePartnerDetails) {
      setTimeout(() => {
        fetchOpenHospitalityStays(intakePartnerDetails.id).then((result) => {
          if (result.success) {
            showRequestSuccessAlert({ label: 'Send to Property Request Sent' });
          } else {
            showAlert({ type: 'error', label: 'Error fetching visits' });
          }
          hideAppModal();
        });
      }, 2000);
    } else {
      showAlert({ type: 'error', label: 'Error making request' });
      setIsRequesting(false);
    }
  };

  const handleClearVisit = async () => {
    setIsRequesting(true);
    const result = await HospitalityService.closeStay(details.hospitalityStay.id);
    if (!result.success) setIsRequesting(false);
  };

  const showDetaillsForm = () => {
    showAppModal({ content: <GuestDetailsForm details={details} /> });
  };

  const handleCardClick = () => {
    if (isRequesting) {
      return;
    }

    if (isReturnedToHotel(details) && !isRequesting) {
      setShowActionButton((prevState) => !prevState);
      return;
    }

    if (isSearchResult(variant)) {
      return;
    }

    if (!isParked(details)) {
      return;
    }

    setShowActionButton((prevState) => !prevState);
  };

  const showTimeStamp = shouldShowTimestamp(details);
  const requestTime = getVehicleEventTimeBasedOnRequestStatus(details);

  return (
    <CardContainer variant={variant} onClick={isRequesting ? undefined : handleCardClick}>
      <DetailsSection>
        <NameAndDetails>
          <CardHeader>
            <Name>
              {`${guestFirstName || driverFirstName} ${guestLastName || driverLastName}`}
              {!roomNumber && (
                <>
                  {' '}
                  <NoPaymentIcon />
                </>
              )}
            </Name>
            {showTimeStamp && requestTime && <Timestamp time={requestTime} />}
          </CardHeader>

          {isSearchResult(variant) && (
            <TimeParked>{getStatusLabel(details.hospitalityStay.status.name)}</TimeParked>
          )}

          <FlexDiv>
            <Details>
              <div>
                <TicketIcon width={14} />
                <div>{`# ${hotelTicketNumber}`}</div>
              </div>

              {!!roomNumber && (
                <div>
                  <DoorIcon width={14} />
                  <div>{`Room ${roomNumber}`}</div>
                </div>
              )}

              {!!details.vehicle && (
                <div>
                  <CarIcon width={14} />
                  <div>{`${details.vehicle.licensePlate.text} - ${details.vehicle.licensePlate.state.name}`}</div>
                </div>
              )}
            </Details>
          </FlexDiv>
        </NameAndDetails>
        <VisitTypeAndMoreInfo>
          {stayType.name === HospitalityStayType.Overnight && <MoonIcon width={18} />}

          {stayType.name === HospitalityStayType.Transient && <TransientIcon width={18} />}
          <InfoIcon
            width={20}
            css="cursor: pointer;"
            onClick={(e) => {
              e.stopPropagation();
              if (isRequesting) return;
              showDetaillsForm();
            }}
          />
        </VisitTypeAndMoreInfo>
      </DetailsSection>

      {(isParkedSearchResult({ details, variant }) || showActionButton) &&
        !isReturnedToHotel(details) && (
          <ActionButtonSection>
            <CardActionButton
              type="button"
              variant={VisitCardActionButtonType.SendToHotel}
              onClick={(e) => {
                e.stopPropagation();
                handleSendToHotelRequest();
              }}
              disabled={
                isRequesting ||
                (isParkedSearchResult({ details, variant }) && !hasRoomNumber({ details })) ||
                (showActionButton &&
                  details.hospitalityStay.stayType.name === HospitalityStayType.Transient) ||
                (showActionButton && isParked(details) && !details.hospitalityStay.roomNumber)
              }
            >
              Send to Hotel
              <ArrowRightIcon />
            </CardActionButton>
          </ActionButtonSection>
        )}

      {!isSearchResult(variant) && isReturnedToHotel(details) && showActionButton && (
        <ActionButtonSection>
          <CardActionButton
            type="button"
            variant={VisitCardActionButtonType.GuestRecievedVehicle}
            onClick={(e) => {
              e.stopPropagation();
              handleClearVisit();
            }}
            disabled={isRequesting}
          >
            <span>{isRequesting ? 'Removing...' : 'Guest Received Vehicle'}</span>
            <GreenCheckIcon />
          </CardActionButton>
        </ActionButtonSection>
      )}
    </CardContainer>
  );
}

export default VisitCard;
