export const breakpoints = {
  small: 445,
  medium: 540,
  large: 705,
};

export const media = {
  small: `(max-width: ${breakpoints.small}px)`,
  medium: `(max-width: ${breakpoints.medium}px)`,
  large: `(max-width: ${breakpoints.large}px)`,
};
