import styled from 'styled-components';

import { media } from 'apps/request/utils/breakpoints';

export const Footer = styled.footer`
  height: 100px;
  background: #ffffff;

  @media ${media.large} {
    height: 70px;
  }

  @media ${media.medium} {
    height: 65px;
  }

  @media ${media.small} {
    height: 55px;
  }
`;

export const FooterContent = styled.div`
  width: calc(100% - 180px);
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 16px;

  @media ${media.large} {
    width: calc(100% - 16px);
    padding: 0 8px;
  }
`;

export const HelpSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  @media ${media.large} {
    padding: 8px 0;
  }
`;

export const HelpLabel = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media ${media.medium} {
    font-size: 12px;
  }

  @media ${media.small} {
    font-size: 10px;
  }
`;

export const HelpEmailSection = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;

  & > a {
    color: #5f59ff;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;

    @media ${media.medium} {
      font-size: 12px;
    }

    @media ${media.small} {
      font-size: 10px;
    }
  }

  @media ${media.medium} {
    font-size: 12px;
  }

  @media ${media.small} {
    font-size: 10px;
  }
`;

export const LegalSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
  color: rgba(0, 0, 0, 0.6);

  & > div:last-of-type {
    display: flex;
    align-items: center;
    color: #000;
    text-decoration: none;
    gap: 8px;
  }

  & a {
    color: #000;
    text-decoration: none;
  }

  @media ${media.large} {
    margin-top: 8px;
  }

  @media ${media.medium} {
    font-size: 10px;
  }

  @media ${media.small} {
    font-size: 8px;
  }
`;
