import {
  Footer,
  FooterContent,
  HelpEmailSection,
  HelpLabel,
  HelpSection,
  LegalSection,
} from './AppFooter.styled';

function AppFooter() {
  return (
    <Footer>
      <FooterContent>
        <HelpSection>
          <HelpLabel>Need Help?</HelpLabel>
          <HelpEmailSection>
            Please email customer service at{' '}
            <a href="mailto:help@metropolis.io" target="_blank" rel="noopener noreferrer">
              help@metropolis.io
            </a>
          </HelpEmailSection>
        </HelpSection>
        <LegalSection>
          <div>
            Copyright @ {new Date().getFullYear()} Metropolis Payments LLC. All rights reserved.
          </div>
          <div css="display: flex; align-items: center: color: #000; text-decoration: none; gap: 8px;">
            <a href="https://www.metropolis.io/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            <span>|</span>
            <a href="https://www.metropolis.io/terms" target="_blank" rel="noopener noreferrer">
              Terms of Use
            </a>
          </div>
        </LegalSection>
      </FooterContent>
    </Footer>
  );
}

export default AppFooter;
