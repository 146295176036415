import styled, { css } from 'styled-components';

import { DashboardView } from 'apps/request/types';
import { media } from 'apps/request/utils/breakpoints';

export const HeaderContainer = styled.header`
  display: flex;
  height: 50px;
  padding: 0 16px;
  border-bottom: 1px solid #bfbfbf;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;

  @media ${media.large} {
    border-bottom: 1px solid #ffffff;
  }
`;

export const LocationName = styled.div`
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
`;

export const pointer = css`
  cursor: pointer;
`;

export const ViewChanger = styled.div`
  height: 64px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;

  @media ${media.small} {
    height: 50px;
    padding: 6px 4px;
  }
`;

export const SliderContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 540px;
  display: flex;
  position: relative;
`;

export const SliderOption = styled.button<{ which: DashboardView; isMobile?: boolean }>`
  width: 50%;
  height: 100%;
  background: #d9d9d9;
  position: relative;
  cursor: pointer;
  user-select: none;
  border: none;

  ${({ which, isMobile }) =>
    which === DashboardView.TODO &&
    !isMobile &&
    `
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  `}

  ${({ which, isMobile }) =>
    which === DashboardView.PARKED &&
    !isMobile &&
    `
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  `}

  ${({ which, isMobile }) =>
    which === DashboardView.TOPARK &&
    isMobile &&
    `
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  `}

  ${({ which, isMobile }) =>
    which === DashboardView.TOPROP &&
    isMobile &&
    `
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  `}
`;

export const OptionText = styled.div<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? '#5F59FF' : '#8E8E8E')};
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 100%;

  @media ${media.small} {
    font-size: 14px;
  }
`;

export const Slider = styled.div<{ selectedOption: DashboardView; isMobile?: boolean }>`
  position: absolute;
  background: #ffffff;
  width: calc(50% - 8px);
  height: calc(100% - 8px);
  border-radius: 50px;
  top: 50%;
  left: 4px;
  transform: translate(0, -50%);
  user-select: none;
  color: #ffffff;
  border: none;
  transition: 0.25s ease-out;

  ${({ selectedOption, isMobile }) =>
    selectedOption === DashboardView.PARKED &&
    !isMobile &&
    `
    transform: translate(calc(100% + 8px), -50%);
  `}

  ${({ selectedOption, isMobile }) =>
    selectedOption === DashboardView.TOPROP &&
    isMobile &&
    `
    transform: translate(calc(100% + 8px), -50%);
  `}
`;

export const MobileParkButton = styled.div<{ active: boolean }>`
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  background: ${({ active }) => (active ? '#5F59FF' : '#FFFFFF')};
  color: ${({ active }) => (active ? '#FFFFFF' : '#000000')};
`;

export const SearchSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const MobileParkedSelectorAlt = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-right: auto;
  margin-left: 12px;
`;
