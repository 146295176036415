import { ReactNode } from 'react';

import { ReactComponent as GreenCheckIcon } from '@m/assets/svg/icon-check-circle-green-white.svg';
import { showAlert } from '@m/state/alert';

import { CustomAlertContainer, requestSuccessStyles } from './RequestSuccess.styled';

type Props = {
  children: ReactNode;
};

export const showRequestSuccessAlert = ({ label }: { label: string }) => {
  showAlert({
    type: 'success',
    label: <RequestSuccess>{label}</RequestSuccess>,
    css: requestSuccessStyles,
  });
};

function RequestSuccess({ children }: Props) {
  return (
    <CustomAlertContainer>
      <GreenCheckIcon />
      {children}
    </CustomAlertContainer>
  );
}

export default RequestSuccess;
