import { useEffect } from 'react';

import { useUnit } from 'effector-react';

import { intakeStore } from '../state/intake';
import { fetchOpenHospitalityStays } from '../state/stays/actions';

let intervalId: any;

export const useHospitalityPolling = ({ interval = 4000, enabled = true }) => {
  const { intakePartnerDetails } = useUnit(intakeStore);

  useEffect(() => {
    if (enabled && intakePartnerDetails) {
      fetchOpenHospitalityStays(intakePartnerDetails.id);

      intervalId = setInterval(() => {
        fetchOpenHospitalityStays(intakePartnerDetails.id);
      }, interval);
    }

    return () => clearInterval(intervalId);
  }, [interval, enabled, intakePartnerDetails]);
};
