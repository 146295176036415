import styled, { css } from 'styled-components';

export const main_sec_styles = css`
  display: flex;
  flex: 1;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ColumnsContainer = styled.div<{ colMaxHeight: number }>`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  gap: 24px;
  padding: 16px;
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > section {
    max-height: ${({ colMaxHeight }) => `${colMaxHeight}px`};
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const IntakePartnerRequestErrorSection = styled.div`
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 24px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1.25px;
  gap: 4px;
`;

export const IntakePartnerRequestErrorLogoutButton = styled.button`
  display: block;
  padding: 18px;
  width: 100%;
  max-width: 284px;
  margin-top: 12px;
  border-radius: 8px;
  background: #5f59ff;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.36px;
  cursor: pointer;
`;
