import { apiFetch } from '@m/utils/http';

import { HospitalityStayStatus, HospitalityStayType, StaySiteType } from '../types';

export type HospitalityStayForm = {
  intakePartnerId: number;
  stayType: HospitalityStayType;
  siteId: number;
  active: boolean;
  visitStart: number;
  visitEnd?: number;
  status: HospitalityStayStatus;
  isOversizedVehicle?: boolean;
  guestFirstName?: string;
  guestLastName?: string;
  driverFirstName: string;
  driverLastName: string;
  hotelTicketNumber: string;
  hotelName: string;
  roomNumber?: string;
  checkInDate?: number;
  checkOutDate?: number;
  siteType: StaySiteType;
};

export type HospitalityEditForm = {
  guestFirstName?: string;
  guestLastName?: string;
  hotelTicketNumber?: string;
  hotelName?: string;
  roomNumber?: string;
  checkInDate?: number;
  checkOutDate?: number;
};

const INTAKE_BASE_PATH = '/v2/intake';

export const HospitalityService = {
  getIntakePartnerDetails: (userId: number) =>
    apiFetch(`${INTAKE_BASE_PATH}/intake-partners/${userId}`),

  getOpenHospitalityStays: (IntakePartnerId: number) =>
    apiFetch(`${INTAKE_BASE_PATH}/intake-partners/${IntakePartnerId}/hospitality-stays`),

  createNewStay: (formValues: HospitalityStayForm) =>
    apiFetch(`${INTAKE_BASE_PATH}/hospitality-stays/create`, {
      method: 'POST',
      body: formValues,
    }),

  editStay: ({
    hospitalityStayUuid,
    formValues,
  }: {
    hospitalityStayUuid: string;
    formValues: HospitalityEditForm;
  }) =>
    apiFetch(`${INTAKE_BASE_PATH}/hospitality-stays/${hospitalityStayUuid}/edit`, {
      method: 'POST',
      body: formValues,
    }),

  requestPickup: (hospitalityStayUuid: string) =>
    apiFetch(`${INTAKE_BASE_PATH}/hospitality-stays/${hospitalityStayUuid}/pickUp`, {
      method: 'PUT',
    }),

  requestDropoff: (hospitalityStayUuid: string) =>
    apiFetch(`${INTAKE_BASE_PATH}/hospitality-stays/${hospitalityStayUuid}/dropOff`, {
      method: 'PUT',
    }),

  closeStay: (hospitalityStayUuid: string) =>
    apiFetch(`${INTAKE_BASE_PATH}/hospitality-stays/${hospitalityStayUuid}/close`, {
      method: 'PUT',
    }),
};
