import styled from 'styled-components';

export const Container = styled.div<{ isUrgent: boolean }>`
  background: #e1e1e1;
  color: #000000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 46px;
  height: 20px;
  padding: 2px 4px;
  gap: 2px;

  ${({ isUrgent }) =>
    isUrgent &&
    `
    background: #FF6652;
    color: #FFFFFF;
  `}
`;
