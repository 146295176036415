import { CONNECT_TO_EXTERNAL_API } from '@m/constants/FeatureFlags';
import { setDefaultAuthMethod } from '@m/utils/http';

import Providers from 'providers';

import Request from './Request';
import { GlobalStyles } from './RequestApp.styled';

if (CONNECT_TO_EXTERNAL_API) setDefaultAuthMethod('token');

export default function RequestApp() {
  return (
    <Providers>
      <Request />
      <GlobalStyles />
    </Providers>
  );
}
