import styled from 'styled-components';

import { HospitalityStayType } from 'apps/request/types';
import { media } from 'apps/request/utils/breakpoints';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  height: calc(100% - 32px);
  width: 100%;
  max-width: 620px;
  border-radius: 8px;
  user-select: none;

  @media ${media.large} {
    height: 100%;
  }
`;

export const FormLocationBanner = styled.header`
  padding: 16px 24px;
  background: #e8e8fe;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: flex-start;
  gap: 12px;

  @media ${media.large} {
    padding: 16px 12px;
  }
`;

export const LocationName = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.16px;
  margin-bottom: 4px;
`;

export const LocationAddress = styled.div`
  color: #666;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.14px;
`;

export const ContentSection = styled.section`
  padding: 32px 24px;
  overflow: scroll;
  display: flex;
  flex: 1;
  flex-direction: column;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${media.large} {
    padding: 16px 12px;
  }
`;

export const FormMainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const EditBtn = styled.button<{ visible?: boolean }>`
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  width: 10px;
  color: #000;

  ${({ visible }) =>
    !visible &&
    `
    visibility: hidden;
  `}
`;

export const MainHeaderText = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;

  @media ${media.large} {
    font-size: 18px;
    line-height: 18px;
  }
`;

export const StayHeading = styled.div<{ addMargin?: boolean }>`
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: ${({ addMargin }) => (addMargin ? '12px;' : '8px;')};

  @media ${media.large} {
    font-size: 18px;
    line-height: 18px;
  }
`;

export const StaySubheading = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #666666;
  margin-bottom: 24px;

  @media ${media.large} {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 18px;
  }
`;

export const StaySelector = styled.div`
  width: 100%;
  height: 56px;
  position: relative;
  display: flex;
  align-items: center;
  border: 2px solid #000000;
  border-radius: 47px;
`;

export const SelectorOption = styled.button<{ whichOpt: HospitalityStayType }>`
  flex: 1;
  height: 100%;
  background: 0;
  border: 0;
  cursor: pointer;

  ${({ whichOpt }) =>
    whichOpt === HospitalityStayType.Overnight &&
    `
    border-radius: 47px 0 0 47px;
  `}

  ${({ whichOpt }) =>
    whichOpt === HospitalityStayType.Transient &&
    `
    border-radius: 0 47px 47px 0;
  `}
`;

export const OptionText = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.36px;
  width: 100%;

  & > div,
  & > svg {
    z-index: 101;
    ${({ isActive }) => (isActive ? 'color: #FFFFFF;' : 'color: #000000;')}
  }

  @media ${media.large} {
    font-size: 14px;
    gap: 4px;
  }
`;

export const Slider = styled.div<{ selectedOpt: HospitalityStayType }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: #000000;
  border-radius: 47px;
  transition: 0.25s ease-out;
  transform: translate(0, 0);

  ${({ selectedOpt }) =>
    selectedOpt === HospitalityStayType.Transient &&
    `
    transform: translate(100%, 0);
  `}
`;

export const FormSection = styled.div`
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Input = styled.input<{ hasError?: boolean }>`
  display: block;
  padding: 18px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 4px;
  border: 1px solid ${({ hasError }) => (hasError ? '#B22234;' : '#8E8E8E;')};

  &:disabled {
    background: #f2f2f2;
    border: 1px solid ${({ hasError }) => (hasError ? '#B22234;' : '#F2F2F2;')};
  }

  ${({ hasError }) =>
    hasError &&
    `
    &::placeholder {
      color: #B22234;
    }
  `}

  @media ${media.large} {
    padding: 14px 12px;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 24px;
  border-top: 1px solid #000000;

  @media ${media.large} {
    padding: 12px;
  }
`;

export const FormButton = styled.button<{ variant?: 'black' }>`
  flex: 1;
  border-radius: 4px;
  background: #5f59ff;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.36px;
  padding: 18px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:disabled {
    opacity: 0.5;
  }

  & > span {
    flex-grow: 1;
    text-align: center;
  }

  & > svg {
    margin-left: auto;
  }

  ${({ variant }) =>
    variant &&
    variant === 'black' &&
    `
    background: #000000;
  `}

  @media ${media.large} {
    padding: 14px;
    font-size: 14px;
  }
`;

export const Separator = styled.div`
  margin: 16px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #666666;

  @media ${media.large} {
    margin: 14px 0;
    font-size: 14px;
  }
`;

export const SearchResultsContainer = styled.div`
  margin-top: 24px;
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const TwoInputContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
`;

export const RoomNumberContainer = styled.div`
  margin-left: auto;
`;

export const PaymentMessage = styled(FlexDiv)`
  gap: 12px;
  color: #0f8f4d;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-top: 24px;
`;

export const PaymentConfirmed = styled(PaymentMessage)`
  gap: 12px;
  color: #0f8f4d;
`;

export const PaymentMissing = styled(PaymentMessage)`
  color: #b22234;
`;

export const ConfirmPaymentSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 12px;
`;
