import styled from 'styled-components';

import { media } from 'apps/request/utils/breakpoints';

export const requestSuccessStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  border-radius: 32px;
  background: #0F8F4D;
  box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.22);
  color: #FFFFFF;
  font-weight: 500;
  padding: 0 8px;
  max-width: 400px;

  @media ${media.large} {
    left: calc(50% - 150px);
    width: 300px;
  }
`;

export const CustomAlertContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
`;
